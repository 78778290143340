export default class FrontPageEnum {
    static BODY = 'body'

    static STRATE_HEADER_SLIDER = '[data-element="strate-header-slider"]'
    static STRATE_HEADER_VIDEO = '[data-customer-header-video]'
    static STRATE_HEADER_VIDEO_POSTER = '[data-customer-header-video-poster]'
    static STRATE_HEADER_VIDEO_MODAL = '[data-customer-header-video-modal]'
    static STRATE_HEADER_VIDEO_MODAL_CLOSE = '[data-customer-header-video-modal-close]'
    static STRATE_HEADER_VIDEO_MODAL_CONTENT = '[data-customer-header-video-modal-content]'
    static STRATE_HEADER_SLIDER_NAVIGATION_PREV =
        '[data-element="strate-header-slider-navigation-prev"]'
    static STRATE_HEADER_SLIDER_NAVIGATION_NEXT =
        '[data-element="strate-header-slider-navigation-next"]'

    static STRATE_MAP_SVG = '[data-element="strate-map-svg"]'
    static STRATE_MAP_SVG_PLACE = '[data-place]'
    static STRATE_MAP_SLIDER = '[data-element="strate-map-slider"]'
    static STRATE_MAP_SLIDER_NAVIGATION_PREV = '[data-element="strate-map-slider-navigation-prev"]'
    static STRATE_MAP_SLIDER_NAVIGATION_NEXT = '[data-element="strate-map-slider-navigation-next"]'
    static STRATE_MAP_MORE_BUTTON = '[data-element="strate-map-more-button"]'

    static STRATE_ITINERARIES = '[data-element="strate-itineraries"]'
    static STRATE_ITINERARIES_HEADING = '[data-element="strate-itineraries-heading"]'
    static STRATE_ITINERARIES_ITEM = '[data-element="strate-itineraries-item"]'
    static STRATE_ITINERARIES_SLIDER = '[data-element="strate-itineraries-slider"]'

    static STRATE_EVENTS_SLIDER = '[data-element="strate-events-slider"]'
    static STRATE_EVENTS_SLIDER_NAVIGATION_PREV =
        '[data-element="strate-events-slider-navigation-prev"]'
    static STRATE_EVENTS_SLIDER_NAVIGATION_NEXT =
        '[data-element="strate-events-slider-navigation-next"]'
    static STRATE_EVENTS_SLIDER_DOTS = '[data-element="strate-events-slider-dots"]'

    static STRATE_AGENDA_SLIDER = '[data-element="strate-agenda-slider"]'
    static STRATE_AGENDA_SLIDER_NAVIGATION_PREV =
        '[data-element="strate-agenda-slider-navigation-prev"]'
    static STRATE_AGENDA_SLIDER_NAVIGATION_NEXT =
        '[data-element="strate-agenda-slider-navigation-next"]'

    static STRATE_INSPIRATIONS_SLIDER = '[data-element="strate-inspirations-slider"]'
    static STRATE_INSPIRATIONS_SLIDER_NAVIGATION_PREV =
        '[data-element="strate-inspirations-slider-navigation-prev"]'
    static STRATE_INSPIRATIONS_SLIDER_NAVIGATION_NEXT =
        '[data-element="strate-inspirations-slider-navigation-next"]'

    static SCROLL_REVEAL_ITEM = '[data-action="scroll-reveal"]'
}
