import FrontPageEnum from '../enumerators/front-page.enum'

export default class FrontPageService {
    constructor() {
        if ($('.home').length > 0) {
            //Header
            if (FrontPageEnum.STRATE_HEADER_VIDEO.length > 0) {
                import(
                    '@scripts/components/front-page/header-video.component' /* webpackChunkName: "scripts/header-video.component" */
                ).then(({ default: HeaderVideoComponent }) => {
                    new HeaderVideoComponent()
                })
            }

            if (!IRISCollectionCustomer.isEcomodeActive) {
                // Sliders
                if (
                    FrontPageEnum.STRATE_HEADER_SLIDER.length > 0 ||
                    FrontPageEnum.STRATE_MAP_SLIDER.length > 0
                ) {
                    import(
                        '@scripts/components/front-page/sliders.component' /* webpackChunkName: "scripts/sliders.component" */
                    ).then(({ default: SlidersComponent }) => {
                        new SlidersComponent()
                    })
                }

                // Animations
                import(
                    '@scripts/components/front-page/animations.component' /* webpackChunkName: "scripts/animations.component" */
                ).then(({ default: AnimationsComponent }) => {
                    new AnimationsComponent()
                })
            }
        }
    }
}
